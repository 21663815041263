import { useDispatch } from 'react-redux'

import { Text } from '@/components/UberForm'
import { StructureDto } from '@/endpoints/models'
import { saveSystem, setTokenVisibility } from '@/store/modules/system/actions'
import { FormValue } from '@/types'

interface SecretInputProps {
	error: string | undefined
	isEditable: boolean
	item: any
	node: StructureDto | undefined
	textOnChange: ((value: FormValue) => void) | undefined
	textRef: React.ForwardedRef<unknown> | undefined
	value: string
}

export const SecretInput = ({
	value,
	textOnChange,
	textRef,
	error,
	node,
	item,
}: SecretInputProps) => {
	const dispatch = useDispatch()
	const handleBlur = () => {
		dispatch(
			setTokenVisibility({
				isBlurred: true,
				nodeId: node?.id,
				onBlurItemId: item.id,
			}),
		)
		dispatch(saveSystem(node))
	}

	return (
		<Text
			autoFocus
			type={item.isBlurred ? 'password' : 'text'}
			value={value}
			onChange={textOnChange}
			onBlur={handleBlur}
			disabled={item.isBlurred}
			className="textInputWithoutBorder"
			error={error}
			refInput={textRef as React.RefObject<HTMLInputElement>}
		/>
	)
}
